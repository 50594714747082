import ContactModalButton from '../components/ContactModalButton';
import { useRef, useState } from 'react';

function Contact() {
    const form = useRef(null)

    const submit = e => {
        e.preventDefault()
        const data = new FormData(form.current)
        fetch('/contact.php', { method: 'POST', body: data })
            .then(body => body.text())
            .then(res => { 
                alert(res)
                e.target.reset();
            })
    }

    return (
        //Form Here
        <>
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <h1 class="header">Contact Me</h1>
                        <div class="main">
                            <form ref={form} onSubmit={submit}>
                                <div class="mb-3">
                                    <label for="fullName" class="form-label">Full Name</label>
                                    <input type="text" class="form-control" id="fullName" name="fName"/>
                                </div>
                                <div class="mb-3">
                                    <label for="emailAddress" class="form-label">Email address</label>
                                    <input type="email" class="form-control" id="emailAddress" name="email"/>
                                </div>
                                <div class="mb-3">
                                    <label for="message" class="form-label">Message</label>
                                    <textarea class="form-control" id="message" rows="3" name="msg"></textarea>
                                </div>
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </form>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <h1 class="header">References</h1>
                        <div class="secondary text-center">
                            <h2 class="header2">Profera, Brenton</h2>
                            <h5>Programming Mentor</h5>
                            <p>Benefitfocus, Software Engineer</p>
                            <ContactModalButton
                                id="contact1"
                                name="Profera, Brenton"
                                phone="843-323-2774"
                                email="brent@prgm.in" />

                            <h2 class="header2">Huff, Joshua</h2>
                            <h5>Ingles Markets Inc.</h5>
                            <p>Assistant Manager, Meat Department</p>
                            <ContactModalButton
                                id="contact3"
                                name="Huff, Joshua"
                                phone="864-515-1912"
                                email="Jochhu@gmail.com" />

                            {/* <h2 class="header2">Dixon, Joshua</h2>
                            <h5>(Placeholder)</h5>
                            <p>(Placeholder), (Placeholder)</p>
                            <ContactModalButton
                                id="contact2"
                                name="Dixon, Joshua"
                                phone="864-205-2345"
                                email="silvermanagarufu@gmail.com" /> */}


                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contact;