import { Modal } from 'bootstrap';

function ContactModalButton({ id, buttonText, name, phone, email }) {
    const handleClick = (e) => {
        const modal = Modal.getOrCreateInstance(document.getElementById(id));
        modal.show();
    };

    return (
        <>
            <button onClick={handleClick}>Contact</button>
            <div class="modal fade" id={id} tabindex="-1" aria-labelledby={id + "Label"} aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content contact">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id={id + "Label"}>{name}</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="mb-3">
                                <h5>Phone: <a href={'tel:' + phone} id={id + "Phone"}>{phone}</a></h5>
                            </div>
                            <div class="mb-3">
                                <h5>Email: <a href={'mailto:' + email} id={id + "Email"}>{email}</a></h5>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactModalButton;