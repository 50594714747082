import { Outlet, NavLink } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <main class="flex-shrink-0">
        <div class="container mt-2">
          <div class="picture">
            <div>
              <img src="assets/portfolioPic.png" alt="No Picture Found"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 mb-3">
              <nav id="navBar">
                <NavLink to="/" className={({ isActive }) => isActive ? "active" : ""}>About Me</NavLink>
                <NavLink to="/work" className={({ isActive }) => isActive ? "active" : ""}>Work</NavLink>
                <NavLink to="/education" className={({ isActive }) => isActive ? "active" : ""}>Education</NavLink>
                <NavLink to="/contact" className={({ isActive }) => isActive ? "active" : ""}>Contact</NavLink>
              </nav>
            </div>
            <div class="col-md-8">
              <Outlet />
            </div>
          </div>
          
        </div>
      </main>

      
    </>
  )
};

export default Layout;