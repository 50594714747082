function About() {
    return (
    // <>/</>: Parent element - needs to be here
    <>
        <div class="container">
          <div class="row">
            <div class="col-md-6 mb-3">
                <h1 class="header">Hi, I'm Ken!</h1>
                <div class="main">
                    <p>Hello, I'm an aspiring software developer and website developer with a keen interest in creating innovative and user-friendly digital experiences. My journey into the world of coding began with a fascination for how websites and applications function, and it has since grown into a dedicated pursuit of mastering various programming languages and frameworks.

Currently, I’m building my expertise in HTML, CSS, JavaScript, and Python. I enjoy the challenge of solving complex problems and transforming ideas into functional, aesthetically pleasing digital solutions. My goal is to develop applications and websites that not only meet user needs but also provide an engaging and seamless experience.

I am a firm believer in continuous learning and staying updated with the latest industry trends and technologies. This approach ensures that my work remains relevant and cutting-edge. Collaboration and communication are also important to me, as I believe that the best solutions come from sharing knowledge and working together.

When I’m not coding, I enjoy exploring new tech gadgets, reading about the latest advancements in technology, and diving into a good book. I’m excited about the future of technology and look forward to contributing to it in meaningful ways.</p>
                </div>
            </div>

            <div class="col-md-6">
                <h1 class="header">Skills</h1>
                <div class="secondary text-center">
                    <h2 class="header2">Languages</h2>
                    <ul>
                        <li>HTML</li>
                        <li>CSS</li>
                        <li>JavaScript</li>
                        <li>React</li>
                        <li>Node.js</li>
                        <li>Python</li>
                        <li>Java</li>
                        <li>C#</li>
                        <li>PHP</li>
                    </ul>
                    <h2 class="header2">Soft Skills</h2>
                    <ul>
                        <li>Communication</li>
                        <li>Teamwork</li>
                        <li>Time Management</li>
                        <li>Analytical Skills</li>
                        <li>Language Acquisition</li>
                        <li>Innovative Thinking</li>
                        <li>Attention to Detail</li>
                    </ul>
                    <h2 class="header2">Apps</h2>
                    <ul>
                        <li>Crystal Reports</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    </>);
}

export default About;