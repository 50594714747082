function Education() {
  return (
  <>
      <div class="container">
          <div class="row">
            <div class="col-md-6">
                <h1 class="header">Education</h1>
                <div class="main">
                <h2 class="header2">Spartanburg Community College</h2>
                    <div class="workHistory">
                        <p>Degree: Assocaites in Science</p>
                        <h4>Graduation Date: 07/2023</h4>
                        <ul>
                            <li>GPA: 3.8/4.0</li>
                            <li>Relevant Coursework: Database Management, Software Engineering</li>
                            <li>Skills Acquired:
                                <ul>
                                    <li>Python, Java</li>
                                    <li>SQL and Database Management</li>
                                    <li>Problem-Solving and Critical Thinking</li>
                                    <li>Networking</li>
                                </ul>
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </div>

            
                <div class="col-md-6">
                    <h1 class="header">Awards</h1>
                    <div class="secondary text-center">
                        <h2 class="header2" id="degreeTitle">Degrees</h2>
                        <img src="" alt="No Image Yet" />
                        <h2 class="header2">Certificates</h2>
                        
                        
                    </div>
                </div>
            
            
        </div>
    </div>
  </>);
}

export default Education;