function Home() {
    return (
    <ul>
        <li>About Me</li>
        <li>Projects</li>
        <li>Education</li>
        <li>Contact</li>
    </ul>);
}

export default Home;