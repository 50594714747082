function Work() {
    return (
        <>
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <h1 class="header">Work History</h1>
                        <div class="main">
                            <h2 class="header2">Ingles Markets Inc</h2>
                            <div class="workHistory">
                                <p>Meat Cutter<br />2018-Present</p>
                                <h3>Responsibilities:</h3>
                                <ul>
                                    <li>Prepare and package meat products for sale to customers</li>
                                    <li>Ensure proper handling and storage of meat products</li>
                                    <li>Maintain a clean and organized work area</li>
                                    <li>Provide excellent customer service and answer questions about products</li>
                                </ul>
                            </div>
                            <h2 class="header2">Freelance</h2>
                            <div class="workHistory">
                                <p>Freelancer<br />2022-Present</p>
                                <h3>Responsibilities:</h3>
                                <ul>
                                    <li>Build and update websites</li>
                                    <li>Work with clients to understand their needs</li>
                                    <li>Manage project deadlines</li>
                                    <li>Fix website issues</li>
                                    <li>Improve website SEO</li>
                                    <li>Make websites mobile-friendly</li>
                                    <li>Use Git for version control</li>
                                </ul>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-6">
                        <h1 class="header">Projects</h1>
                        <div class="secondary text-center">
                            {/* <button class="projects"><img src="" alt="No Image Yet" /></button> */}
                            <button class="projects"><span style={{color:"cyan"}}>Coming Soon...</span></button>
                        </div>
                    </div>


                </div>
            </div>
        </>
    );
}

export default Work;